<template>
  <div class="d-flex flex-row">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom">
        <div class="card-body pt-15">
          <div class="text-center mb-10">
            <div class="symbol symbol-60 symbol-circle shadow symbol-xl-90">
              <div
                class="symbol-label"
                :style="{
                  backgroundImage: `url(${avatar})`
                }"
              ></div>
              <i class="symbol-badge symbol-badge-bottom bg-success"></i>
            </div>

            <h4 class="font-weight-bold my-2">
              {{ firstname }} {{ lastname }}
            </h4>
            <span class="text-sm">Member Since </span>
            <time-ago
              class="text-sm"
              :refresh="60"
              :datetime="created_at"
              locale="en"
              :long="true"
              tooltip
            ></time-ago>
            <div class="text-muted mb-2">
              {{ user_role.name }}
            </div>
            <span
              v-if="active"
              class="label label-light-success label-inline font-weight-bold label-lg"
              >Active</span
            >
            <span
              v-if="!active"
              class="label label-light-danger label-inline font-weight-bold label-lg"
              >Deactivated</span
            >
          </div>
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <div class="navi-item mb-2">
              <a
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block active"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="0"
                data-toggle="tab"
                role="tab"
              >
                Profile Overview
              </a>
              <a
                v-if="this.currentUser.company.action_party_type == 'group'"
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="1"
                data-toggle="tab"
                role="tab"
              >
                Action Parties
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab active>
          <div class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-3">
              <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">
                  Personal Information
                </h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1"
                  >Update user informaiton</span
                >
              </div>
              <div class="card-toolbar">
                <button
                  type="submit"
                  class="btn btn-primary mr-2"
                  @click="updateUser()"
                  ref="kt_save_changes"
                >
                  Update User
                </button>
                <button
                  type="reset"
                  class="btn btn-secondary"
                  @click="cancel()"
                >
                  Back
                </button>
              </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <form class="form">
              <!--begin::Body-->
              <div class="card-body">
                <div class="row">
                  <label class="col-xl-3"></label>
                  <div class="col-lg-9 col-xl-6"></div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right"
                    >First Name</label
                  >
                  <div class="col-lg-9 col-xl-6">
                    <input
                      ref="firstname"
                      disabled
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      v-bind:value="firstname"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right"
                    >Last Name</label
                  >
                  <div class="col-lg-9 col-xl-6">
                    <input
                      ref="lastname"
                      disabled
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      v-bind:value="lastname"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right"
                    >User Role</label
                  >
                  <div class="col-lg-9 col-xl-6">
                    <multiselect
                      v-model="user_role"
                      :options="roles"
                      track-by="name"
                      label="name"
                      placeholder="Select user role"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="row">
                  <label class="col-xl-3"></label>
                  <div class="col-lg-9 col-xl-6">
                    <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right"
                    >Contact Phone</label
                  >
                  <div class="col-lg-9 col-xl-6">
                    <div class="input-group input-group-lg input-group-solid">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="la la-phone"></i>
                        </span>
                      </div>
                      <input
                        ref="phone"
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Phone"
                        v-bind:value="phone"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right"
                    >Email Address</label
                  >
                  <div class="col-lg-9 col-xl-6">
                    <div class="input-group input-group-lg input-group-solid">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="la la-at"></i>
                        </span>
                      </div>
                      <input
                        ref="email"
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Email"
                        v-bind:value="email"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </form>
            <!--end::Form-->
          </div>
        </b-tab>
        <b-tab v-if="this.currentUser.company.action_party_type == 'group'">
          <div class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-3">
              <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">
                  User Action Parties
                </h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1"
                  >Action party information</span
                >
              </div>
              <div class="card-toolbar"></div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <form class="form">
              <!--begin::Body-->
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-8"
                    v-for="item in actionParties"
                    :key="item.id"
                  >
                    <div class="card p-6 mb-2 action-party shadow border">
                      <div class="d-flex justify-content-between">
                        <div class="d-flex flex-row align-items-center">
                          <div class="icon">
                            {{ item.name.charAt(0) }}
                          </div>
                          <div class="ml-2">
                            <h6 class="mb-0">{{ item.name }}</h6>
                            <span
                              ><time-ago
                                class="text-sm"
                                :refresh="60"
                                :datetime="item.created_at"
                                locale="en"
                                :long="true"
                                tooltip
                              ></time-ago
                            ></span>
                          </div>
                        </div>
                        <div class="badge">
                          <span>{{ item.user_count }} members</span>
                        </div>
                      </div>
                      <div class="mt-5">
                        <p>{{ item.description }}</p>
                        <div class="row">
                          <div class="col-9">
                            <div class="stacked-avatars">
                              <template v-if="item.users.length > 0">
                                <span
                                  v-for="(e, i) in 5"
                                  :key="i"
                                  class="avatar"
                                >
                                  <img
                                    class="shadow border"
                                    v-b-tooltip.hover.top="
                                      item.users[i].firstname +
                                        ' ' +
                                        item.users[i].lastname
                                    "
                                    v-if="item.users[i]"
                                    :src="item.users[i].avatar"
                                  />
                                </span>
                              </template>
                              <template v-if="item.users.length == 0">
                                <span class="shadow border span-avatar">.</span>
                              </template>
                            </div>
                          </div>
                          <div class="col-3 text-right">
                            <span
                              v-if="isInParty(item)"
                              @click="updateUserParties(item, false)"
                              class="label label-light-danger label-inline font-weight-bold cursor-pointer label-lg"
                              >Remove</span
                            >
                            <span
                              v-if="!isInParty(item)"
                              @click="updateUserParties(item, true)"
                              class="label label-light-success label-inline font-weight-bold cursor-pointer label-lg"
                              >Add</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-9 col-xl-6"></div>
                </div>
              </div>
              <!--end::Body-->
            </form>
            <!--end::Form-->
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { mapGetters } from "vuex";
import { Multiselect } from "vue-multiselect";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ACTION_PARTIES } from "@/core/services/store/actionparty.module";
import { TimeAgo } from "vue2-timeago";
import Swal from "sweetalert2";
import RoleService from "@/core/services/role.service";
import UserService from "@/core/services/user.service";

export default {
  name: "User-Details",
  components: {
    TimeAgo,
    Multiselect
  },
  data() {
    return {
      tabIndex: 0,
      avatar: null,
      firstname: null,
      lastname: null,
      created_at: null,
      phone: null,
      email: null,
      active: null,
      user_role: null,
      roles: [],
      party: null,
      parties: []
    };
  },
  mounted() {
    this.getUser();
    this.getRoles();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users", route: "users" },
      { title: "User Details" }
    ]);
    this.$store.dispatch(GET_ACTION_PARTIES);
  },
  methods: {
    isInParty(item) {
      const user_parties = this.parties.map(a => a.id);
      return user_parties.includes(item.id);
    },
    getUser() {
      UserService.get(this.$route.params.id)
        .then(response => {
          this.updateUserDetails(response.data);
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate users at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getRoles() {
      RoleService.all()
        .then(response => {
          this.roles = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate user roles at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    updateUser() {
      if (this.user_role === null) {
        this.makeToast(
          "danger",
          "Validation Error",
          "Please select a user role"
        );
        return;
      }

      const payload = {
        firstname: this.firstname,
        lastname: this.lastname,
        role_id: this.user_role.id,
        phone: this.phone
      };

      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      UserService.update(payload, this.$route.params.id)
        .then(response => {
          this.updateUserDetails(response.data.user);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          this.makeToast("success", "User Updated", response.data.message);
        })
        .catch(error => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured, please give it a minute, refresh page and try again"
          );
          console.log(error);
        });
    },
    updateUserParties(item, add) {
      const userid = this.$route.params.id;
      const stateAction = add == true ? "Add" : "Remove";
      const stateOutcome = add == true ? "added" : "removed";

      const payload = {
        user: userid,
        action_party: item.id
      };

      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to ${stateAction} user to ${item.name}`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: stateAction,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return UserService.updateParty(add, payload)
            .then(response => {
              this.parties = response.data.user.action_parties;
              this.$store.dispatch(GET_ACTION_PARTIES);
              return response;
            })
            .catch(error => {
              Swal.showValidationMessage(`Operation Failed: ${error.message}`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          this.makeToast(
            "success",
            `User ${stateOutcome}`,
            `User was successfully ${stateOutcome} to ${item.name}`
          );
        }
      });
    },
    updateUserDetails(response) {
      this.avatar =
        response.avatar == null ? this.placeholder : response.avatar;
      this.firstname = response.firstname;
      this.lastname = response.lastname;
      this.phone = response.phone;
      this.email = response.email;
      this.active = response.active;
      this.user_role = response.role;
      this.created_at = response.created_at;
      this.parties = response.action_parties;
    },
    setActiveTab(event) {
      let target = event.target;
      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('[data-toggle="tab"]');
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.tabIndex = parseInt(target.getAttribute("data-tab"));
      target.classList.add("active");
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig", "hasPermission"]),
    actionParties() {
      return this.$store.state.actionParty.actionParties;
    },
    placeholder() {
      return this.layoutConfig("avatar");
    }
  }
};
</script>
